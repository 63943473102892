import React from 'react';
import './index.css';

function Boxes() {
  return (
    <div>
       <div className="context">
      </div>
      <div className="area" >
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
    
  )
}

export default Boxes;
