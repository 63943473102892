import React, { useState, useEffect } from 'react';
import Radium from 'radium';
import 'antd/dist/antd.css';
import download from 'downloadjs';
import { Button, Card, Divider, Input, message, Spin } from 'antd';
import { get_order_for_return } from './api/cloudfunctions/orders';
import SetSchedule from './components/SetSchedule';
import moment from 'moment-timezone';
import {SearchOutlined} from '@ant-design/icons';
import Boxes from './common/backgrounds/boxes/Boxes';
import {PDFDocument, StandardFonts} from 'pdf-lib';
import logo from './assets/logo.png';

function App() {

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<any>(null);
  const [returnSet, setReturnSet] = useState<any>(null);
  const [returningOrder, setReturningOrder] = useState<any>(null)
  const [inputText, setInputText] = useState<string>('');

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    const orderId = window.location.pathname.slice(1);
    setLoading(true);
    try {
      const {data} = await get_order_for_return({orderId})
      if (data.returnSet && data.returningOrder) {
        setReturnSet(data.returnSet);
        setReturningOrder(data.returningOrder);
      }
      else {
        setOrder(data);
      }
    }
    catch (err: any) {
      console.log(err.message);
    }
    finally {
      setLoading(false);
    }
  }

  const goToTrackingURL = (inputText: string) => {
    window.location.href = inputText
  }

  const printLabel = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([297.64, 419.53]);
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const timesRomanBoldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
    const fontSize = 12;

    const merchantName = returningOrder.corporationName;
    // const toAddress = returningOrder.verified.formattedAddress.split(', ');

    const qrUrl = `https://quickchart.io/qr?text=${encodeURI(returningOrder.id)}`;
    const qrImageBytes = await fetch(qrUrl).then((res) => res.arrayBuffer());
    const qrImage = await pdfDoc.embedPng(qrImageBytes);

    const logoImageBytes = await fetch(logo).then((res) => res.arrayBuffer());
    const logoImage = await pdfDoc.embedPng(logoImageBytes);

    const fromAddress = returningOrder.origin.verified.formattedAddress.split(', ');

    page.drawImage(qrImage, {
      x: page.getWidth() / 2 - 100,
      y: page.getHeight() / 2 - 40,
      width: 200,
      height: 200,
    });

    page.drawImage(logoImage, {
      x: 8,
      y: page.getHeight() - 38,
      width: 90,
      height: 22,
    });

    page.drawText(`Order: ${returningOrder?.orderNumber}`, {
      x: page.getWidth() - 100,
      y: page.getHeight() - 25,
      font: timesRomanFont,
      size: fontSize,
    });

    page.drawLine({
      start: {x: 0, y: page.getHeight() / 2 - 55},
      end: {x: page.getWidth(), y: page.getHeight() / 2 - 55},
      thickness: 1,
      opacity: 0.75,
    });

    page.drawText('TO', {
      x: 10,
      y: page.getHeight() / 2 - 75,
      font: timesRomanFont,
      size: 14,
    });

    page.drawText(`${merchantName}`, {
      x: page.getWidth() / 8,
      y: page.getHeight() / 2 - 90,
      font: timesRomanBoldFont,
      size: fontSize,
    });

    // page.drawText(`${toAddress[0]}`, {
    //   x: page.getWidth() / 8,
    //   y: page.getHeight() / 2 - 105,
    //   font: timesRomanFont,
    //   size: fontSize,
    // });

    // page.drawText(`${toAddress[1]}, ${toAddress[2] || ''}, ${toAddress[3] || ''}`, {
    //   x: page.getWidth() / 8,
    //   y: page.getHeight() / 2 - 120,
    //   font: timesRomanFont,
    //   size: fontSize,
    // });

    page.drawLine({
      start: {x: 0, y: page.getHeight() / 2 - 140},
      end: {x: page.getWidth(), y: page.getHeight() / 2 - 140},
      thickness: 1,
      opacity: 0.75,
    });

    page.drawText('FROM', {
      x: 10,
      y: page.getHeight() / 2 - 160,
      font: timesRomanFont,
      size: 14,
    });

    page.drawText(`${fromAddress[0]}`, {
      x: page.getWidth() / 8,
      y: page.getHeight() / 2 - 180,
      font: timesRomanFont,
      size: fontSize,
    });
    page.drawText(`${fromAddress[1]}, ${fromAddress[2] || ''}, ${fromAddress[3] || ''}`, {
      x: page.getWidth() / 8,
      y: page.getHeight() / 2 - 195,
      font: timesRomanFont,
      size: fontSize,
    });

    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, 'shippie_order_labels.pdf', 'application/pdf');

  }

  const renderSchedule = () => {
    if (returnSet) {
      return (
        <Card style={comStyles().contents}>
          <p style={comStyles().title}>Pickup Scheduled</p>
          <Divider/>
          <p>
            This pickup is scheduled for: <br/>
            <b>{returnSet.formattedAddress}</b> <br/><br/>
            On: <b>{moment(returnSet.date).format("MMMM Do YYYY")}</b> <br/>
            Between:<b>{returnSet.timeframe}</b>
          </p>
          <p style={{margin: 20}} >Please print this label and attach it to your returning package</p>
          <Button type='primary' onClick={printLabel}>Print Label</Button>
          <p style={{margin: 20}}>If there are any issues, please contact <br/> <a href='www.shippie.ca/support'>Shippie Support</a></p>
        </Card>
      )
    }
    else if (order) {
      return (<SetSchedule fullName={order.fullName} orderId={order.orderId} formattedAddress={order.formattedAddress} />)
    }
    else {
      return (
        <Card style={comStyles().contents}>
          <div style={comStyles().title}>Shippie</div>
          <div>Return your order</div>
          <Divider/>
          <Input style={comStyles().input} placeholder='Order Tracking ID' onChange={(e) => setInputText(e.target.value)} />
          <div style={comStyles().buttonContainer}>
            <Button style={comStyles().button} 
              disabled={loading || inputText.length === 0} 
              loading={loading} 
              type='primary'
              size="large"
              icon={<SearchOutlined />}
              onClick={() => goToTrackingURL(inputText)}>
              Return Order
            </Button>
          </div>
          <div style={{ height: `30px` }} />
          <div style={comStyles().footer}>Shippie Technologies Inc</div>
        </Card>
      )
    }
  }

  return (
    <>
      <Boxes />
      <div style={comStyles().container}>
        {
          loading
          ?
          <Spin />
          :
          renderSchedule()
        }
      </div>
    </>
  );
}

const RadiumHOC = Radium(App);
export default RadiumHOC;

// the JS function that returns Radium JS styling
const comStyles: () => {[key: string]: React.CSSProperties} = () => {
  return {
    container: {
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
      height: `100vh`,
      width: `100vw`,
      position: `absolute`,
      top: 0,
      left: 0,
    },
    contents: {
      display: `flex`,
      flexDirection: `column`,
      width: `400px`,
      minHeight: `100%`,
      maxHeight: `100%`,
      margin: `20px 0px`,
      padding: `50px 0px`,
      overflowY: `scroll`,
      textAlign: 'center'
    },
    body: {
      flexDirection: `column`,
      width: `400px`,
      minHeight: `100%`,
      maxHeight: `100%`,
      margin: `20px 0px`,
      padding: `50px 0px`,
      overflowY: `scroll`,
      textAlign: 'center'
    },
    title: {
      fontSize: `2rem`,
      fontWeight: `bold`,
    },
    footer: {
      display: `flex`,
      flexDirection: `row`,
      justifyContent: `center`,
      alignItems: `center`,
      width: `100%`,
    },
    input: {
      margin: `15px 0px`,
      padding: `10px 20px`,
      height: `50px`,
    },
    buttonContainer: {
      alignItems: `center`,
      display: `flex`,
      flexDirection: `row`,
      justifyContent: `center`,
    },
    button: {
      padding: `10px 20px`,
      margin: `11px 0px`,
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
      height: `50px`,
    },
  };
};