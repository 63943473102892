import React, { useState, useEffect } from 'react';
import Radium from 'radium';
import { Col, DatePicker, Divider, Row, TimePicker, Input, Button, Modal, message, Select, Card } from 'antd';
import moment from 'moment-timezone';
import { return_requested } from '../api/cloudfunctions/orders';
const { TextArea } = Input;
const { Option } = Select;
function SetSchedule({orderId, formattedAddress, fullName}: any) {

  const [loading, setLoading] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const [timeframe, setTimeframe] = useState<string>('4pm - 9pm');

  const [showModal, setShowModal] = useState(false);

  const onDateChange = (date: any, dateString: string) => {
    setDate(dateString);
  }


  const disabledDates = (current: any) => {
    return moment(current).day() === 0 || moment(current).day() === 6 || current && current < moment().add(1, 'day').endOf('day');
  }

  const onConfirmPickup = () => {
    if (date.length > 0 && timeframe.length > 0) {
      setShowModal(true)
    }
    else {
      message.error('Missing Date and Time')
    }
  }

  const createReturnOrder = async () => {
    setLoading(true);
    try {
      const sendLog = await return_requested({fullName, formattedAddress, date, timeframe, phoneNumber, notes, orderId});
      message.success('Success');
      window.location.reload();
    }
    catch (err: any) {
      message.error(err.message);
    }
  }
  
  return (
    <Card style={comStyles().contents}>
      <p style={comStyles().title}>Schedule a Return</p>
      <Divider />
      <div style={comStyles().body}>
        <b>From: {formattedAddress}</b>
        <p style={comStyles().cardText}>
          Select a time that works with you!
          We will try our best to schedule a driver for your time (Keep a few hours buffer)
        </p>
        <Row style={comStyles().row}>
          <Col flex={1}>
            <p style={comStyles().inputTitle}>Date: </p>
          </Col>
          <Col flex={4}>
            <DatePicker style={comStyles().pickers} disabledDate={disabledDates} onChange={onDateChange} />
          </Col>
        </Row>
        <Row style={comStyles().row}>
          <Col flex={1}>
            <p style={comStyles().inputTitle}>Time: </p>
          </Col>
          <Col flex={4}>
            <Select defaultValue={timeframe} style={comStyles().pickers} onChange={(e) => setTimeframe(e)} >
              <Option value={'4pm - 9pm'}>4pm - 9pm</Option>
            </Select>
          </Col>
        </Row>
        <Row style={comStyles().row}>
          <Col flex={1}>
            <p style={comStyles().inputTitle}>Phone: </p>
          </Col>
          <Col flex={4}>
            <Input onChange={(e) => setPhoneNumber(e.target.value)} placeholder='+16472547328' />
          </Col>
        </Row>
        {
          date.length > 0 && timeframe.length > 0 &&
          <p style={{color: 'green'}}>Your driver will arrive on <b>{date}</b> between <b>{timeframe}</b> </p>
        }
        
        <Row style={comStyles().row}>
          <p style={comStyles().inputTitle}>Pickup Notes: </p>
          <TextArea placeholder='Notes for the pickup driver ' rows={4} onChange={(e) => setNotes(e.target.value)}/>
        </Row>
        
        <p style={comStyles().cardText}>
          Please be ready to hand off your package when the driver arrives, this allows us to continue this service :)
        </p>
        
      </div>
      <Button onClick={onConfirmPickup} type='primary' style={{float: 'right'}}>Confirm Pickup</Button>
      
      <Modal 
        title="Confirm Pickup and Print Label" 
        visible={showModal}
        onOk={createReturnOrder}
        onCancel={() => setShowModal(false)}
        okText="Confirm"
        cancelText="Cancel"
        okButtonProps={{ disabled:  loading  }}
      >
        {date.length > 0 && timeframe.length > 0 &&
          <p style={comStyles().cardText}>
            {/* Please print this <b>PDF</b> label and attach it to your returning package.
            <br/><br/> */}
            A driver will meet you at <br/> <b>{formattedAddress}</b> <br/><br/> On <b>{date}</b> between <b>{timeframe}</b>.
            <br/><br/>
            This return cannot be changed, please contact <a href='www.shippie.ca/contact'>Shippie Support</a> for any issues.
          </p>
        }
      </Modal>
    </Card>
  );
}

const RadiumHOC = Radium(SetSchedule);
export default RadiumHOC;

// the JS function that returns Radium JS styling
const comStyles: () => {[key: string]: React.CSSProperties} = () => {
  return {
    contents: {
      display: `flex`,
      flexDirection: `column`,
      width: `400px`,
      minHeight: `100%`,
      maxHeight: `100%`,
      margin: `20px 0px`,
      padding: `50px 0px`,
      overflowY: `scroll`,
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      textAlign: 'center'
    },
    inputTitle: {
      fontWeight: 'bold',
      fontSize: 16
    },
    cardText: {
      fontSize: 16,
      marginBottom: 20,
      marginTop: 20,
    },
    row: {
      marginTop: 20
    },
    pickers: {
      width: '100%'
    }
  };
};